import React from "react";

const Contact = () => {
    return (
        <div>
            <div class="contactWrapper container-fluid bgColorGreen">
                <div>
                    <h2 class="sectionHeader headingTextDecor fontColorCream">Contact</h2>
                </div>

                <div class="contactForm">
                    <form action="https://submit-form.com/vg0Wca9Ni">
                        <div class="row">
                            <div class="col contactInputDiv">
                                <input type="text" name="First name" class="form-control" placeholder="First name" required></input>
                            </div>
                            <div class="col contactInputDiv">
                                <input type="text" name="Last name" class="form-control" placeholder="Last name" required></input>
                            </div>
                            <div class="contactInputDiv">
                                <input type="email" name="Email" class="form-control " placeholder="Email" required></input>
                            </div>
                            <div class="contactInputDiv">
                                <input type="tel" name="Phone" class="form-control" maxlength="10" placeholder="Phone" required></input>
                            </div>
                            <div class="contactInputDiv">
                                <input type="text" name="Subject" class="form-control" placeholder="Subject" required></input>
                            </div>
                            <div class="contactInputDiv">
                                <textarea name="Message" class="form-control" rows="4" placeholder="Message" required></textarea>
                            </div>
                            <div class="contactInputDiv text-center">
                                <button type="submit" class="contactFormBtn" aria-label="Let's Chat">Let's Chat!</button>
                            </div>
                            <div>
                                <input type="hidden" name="_cc" value="kdwebdevelopment0@gmail.com"></input>
                                <input type="hidden" name="_template" value="box"></input>
                                <input type="hidden" name="_autoresponse" value="Thank you for your message. I will try to get back to you as soon as possible."></input>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
            
        </div>
    );
}

export default Contact;