import React from "react";

const Portfolio = () => {
    return (
        <div>

            <div class="portfolioWrapper container-fluid">
                <div>
                    <h2 class="sectionHeader headingTextDecor fontColorGreen">Portfolio</h2>
                </div>
                <div class="porfolioFeaturedSites">
                    <a href="https://www.defensetherapy.com/" target="_blank" rel="noopener noreferrer">
                        <img class="portfolioImgSize" src="/images/DefenseTherapy_bubble.png" alt="Birthday Everyday 365 Website"></img>
                    </a>
                    
                    
                    {/* 
                    <a href="https://soulercise.coggollie.org/" target="_blank" rel="noopener noreferrer">
                        <img class="portfolioImgSize" src="/images/Soulercise_bubble.png" alt="CoggOllie Soulercisse Website"></img>
                    </a>
                    */}
                </div>

                {/*
                <div class="portfolioToggleBtn">
                    <p class="d-inline-flex gap-1">
                        <button class="seeMoreBtn" type="button" data-bs-toggle="collapse" data-bs-target=".multi-collapse" aria-label="See more">See More</button>
                    </p>
                </div>
                 */}
                
                {/* 
                <div class="portfolioSeeMoreSection">
                    <div class="collapse multi-collapse">
                        <div class="seeMoreContent">
                            <div class="portfoliorSeeMoreSites">
                            <a href="https://coggollie.org/" target="_blank" rel="noopener noreferrer">
                                <img class="portfolioImgSize" src="/images/CoggOllie_bubble.png" alt="CoggOllie Brand Website"></img>
                            </a> 
                                <a href="https://management.coggollie.org/" target="_blank" rel="noopener noreferrer">
                                    <img class="portfolioImgSize" src="/images/CoggOllieManagement_bubble.png" alt="CoggOllie Brand Site"></img>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                */}
               
               <div className="seeProjectsBtn">
                    <a className="seeProjectsLink" href="/projects">See more projects</a>
               </div>
                
            </div>
            
        </div>
    );
}
export default Portfolio;